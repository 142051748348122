import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Company from "./components/company/Company";
import Courses from "./components/courses/Courses";
import Loading from "./components/courses/Loading";
import CoursesList from "./components/courseslist/CoursesList";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Login from "./components/login/Login";
import Navbar from "./components/Navbar/Navbar";
import Pathway1 from "./components/Pathway/Pathway1";
import { Testmonial } from "./components/testmonial/Testmonial";
import Thankyou from "./components/thankyou/Thankyou";
import Writeus from "./components/Writeus/Writeus";
import Home from "./pages/Home";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thankyou" exact element={<Thankyou />} />

        <Route path="/courses" element={<Courses />} />
        <Route path="/courselist" element={<CoursesList />} />
        <Route path="/pathway/:id" element={<Pathway1 />} />
        <Route path="/login" element={<Login />} />

        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
