import React from "react";

const HeaderCards = () => {
  return (
    <>
      <div className="header-cards">
        <h4> 10+ domains</h4>
        <h1>We help you save time & money</h1>
        <p>
          We curate best free and paid courses only , according to your
          difficulty level
        </p>
      </div>
      <div className="header-card-container">
        <div className="header-card">
          <div className="header-card-icon">
            <i class="bx bx-question-mark"></i>
          </div>
          <h3>Best Courses</h3>
          <div className="header-card-line"></div>
          <div className="header-card-txt">
            We curate the best courses based on platform experience,content
            projects.Instructor profile and much more.
          </div>
        </div>

        <div className="header-card">
          <div className="header-card-icon">
            <i class="bx bx-purchase-tag-alt"></i>
          </div>
          <h3>All Levels</h3>
          <div className="header-card-line"></div>
          <div className="header-card-txt">
            We believe there is no limit for learning so we have courses for
            people who are in different stages of their career.
          </div>
        </div>
        <div className="header-card">
          <div className="header-card-icon">
            <i class="bx bxs-coin-stack"></i>
          </div>
          <h3>Exclusive Discounts</h3>
          <div className="header-card-line"></div>
          <div className="header-card-txt">
            Neolearn will help you to save your time and money by provide
            exclusive discounts.
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderCards;
