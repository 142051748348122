import Airtable from "airtable";

// // api keys are confidential
// const base = new Airtable({ apiKey: "keycymBVZPdYKaDzj" }).base(
//   "appgnxqFT4FPVL46W"
// );

var base = new Airtable({ apiKey: "keycymBVZPdYKaDzj" }).base(
  "appgnxqFT4FPVL46W"
);
export default base;
