import React, { useEffect, useState } from "react";
import "./courseslist.css";
import Tags from "./Tags";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { Link } from "react-router-dom";
import "../Pathway/pathway.css";
import Footer2 from "../Footer2/Footer2";
import Navbar2 from "../Navbar2/Navbar2";
import { color } from "@mui/system";
import toast, { Toaster } from "react-hot-toast";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";

import { steps } from "./steps";
import { useContext } from "react";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

function Button() {
  const tour = useContext(ShepherdTourContext);

  return (
    <button className="tour-btn" onClick={tour.start}>
      Start Tour
    </button>
  );
}

const CoursesList = () => {
  const [age, setAge] = React.useState("");
  const [toggleState, setToggleState] = useState(1);
  const [pathways, setpathways] = useState();
  const [masterinone, setmasterinone] = useState();
  const [specalized, setspecalized] = useState();
  const [searchkey, setsearchkey] = useState("");
  const [loading, setloading] = useState(true);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  async function searchandfilterfunction() {
    const resppathway = await axios.get(
      `https://neolearn-backend.onrender.com/api/pathway?search=${searchkey}`
    );
    const respmio = await axios.get(
      `https://neolearn-backend.onrender.com/api/course?data=mio&search=${searchkey}`
    );

    const respcs = await axios.get(
      `https://neolearn-backend.onrender.com/api/course?data=cs&search=${searchkey}`
    );
  }

  useEffect(() => {
    async function getapi() {
      try {
        // const resp = await axios.get(
        //   "https://neolearn-backend.onrender.com//api/pathway?search=react"
        // );
        // const resp2 = await axios.get(
        //   ` https://neolearn-backend.onrender.com/api/course`
        // );
        const resp1 = await axios.get(
          "https://neolearn-backend.onrender.com/api/pathway"
        );
        setpathways(resp1.data.message);
        if (resp1.data) {
          setloading(false);
        }

        const specires = await axios.get(
          `https://neolearn-backend.onrender.com/api/course?data=cs`
        );

        setspecalized(specires.data.message);
        const masterres = await axios.get(
          `https://neolearn-backend.onrender.com/api/course?data=mio`
        );
        setmasterinone(masterres.data.message);
      } catch (error) {
        return "something went wrong";
      }
    }
    getapi();
  }, []);

  return (
    <>
      {" "}
      <Toaster position="bottom-left" reverseOrder={false} />
      <Navbar2 />
      <div className="courseslist-container">
        <div className="c-list-heading">
          <h1>
            Explore Pathways{" "}
            {!loading && (
              <ShepherdTour steps={steps} tourOptions={tourOptions}>
                <Button />
              </ShepherdTour>
            )}
          </h1>
          <h2 className="mt-1">Learn anything with our curated pathways</h2>
        </div>

        <p></p>
        {/* <div className="courselist-fields">
          <div className="searchbar mt-2">
            <input
              type="text"
              placeholder="Search for best hand picked courses"
              value={searchkey}
              onChange={(e) => setsearchkey(e.target.value)}
            />
            <span
              className="search-icon-container"
              onClick={searchandfilterfunction}
            >
              <i className="bx bx-search-alt search-icon"></i>
            </span>
          </div>
          <div className="select-categories">
            <select name="categories" id="catetogies">
              <option value="full stack">Full stack</option>
              <option value="android">Andriod</option>
              <option value="ios">Ios</option>
              <option value="native">Native</option>
              <option value="blockchain">blockchain</option>
            </select>
          </div>
        </div> */}
        {/* <div className="course-filters mt-2">
          <div className="filter">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Age</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div> */}
        {toggleState == "1" && (
          <div className="tab-heading">
            <b>Pathway</b>
            <p>
              Pathways are learning resources used by professionals organized in
              a structured manner to help you learn and achieve your career goal
              in best possible way
            </p>
          </div>
        )}
        {toggleState == "2" && (
          <div className="tab-heading" style={{ backgroundColor: "#FFF7E1" }}>
            <b>Master in one Course</b>
            <p>
              Learn about various career oppurtunities and master it in fron one
              single course. One of the best and quick way to learn things
            </p>
          </div>
        )}

        {toggleState == "3" && (
          <div className="tab-heading" style={{ backgroundColor: "#EDFFF4" }}>
            <b>Learn Advanced concepts</b>
            <p>
              Learn specific things helps you to learn advanced concepts or
              build specialized skills which helps you to excel in your career
              and stand out from the crowd.
            </p>
          </div>
        )}

        {loading ? (
          <div className="gif-container">
            <div>
              <img
                className="loading-gif"
                src="https://res.cloudinary.com/neolearn/image/upload/v1668395051/72266-vr-learning_naljud.gif"
                alt=""
              />
            </div>
            <div>
              <h2 className="mt-1">
                Getting Awesome Learning Resource for you 😍
              </h2>
            </div>
          </div>
        ) : (
          <>
            <div className="bloc-tabs">
              <button
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(1)}
              >
                Detailed Pathways
              </button>
              <button
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(2)}
              >
                Master in one Course
              </button>
              <button
                className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(3)}
              >
                Learn Specific things
              </button>
            </div>
            <div className="pathways-list">
              {toggleState == 1 &&
                pathways &&
                pathways.map((data, id) => {
                  return (
                    <>
                      <div className="courses-list">
                        <div className="course-card">
                          {data.displayPic && (
                            <img
                              src={data.displayPic}
                              alt=""
                              className="course-img"
                            />
                          )}

                          <div className="course-details">
                            {data.pathwayName && <h2>{data.pathwayName}</h2>}

                            <div className="course-created-by">
                              <div className="curator-details-course-list">
                                <div>
                                  {data && (
                                    <img
                                      src={data.profilePic}
                                      alt=""
                                      className="profile-pic"
                                    />
                                  )}
                                  <p>
                                    {data && data.curatedBy} <br />
                                    {data && data.personDesignation}
                                  </p>
                                  {/* {data.companyPic && (
                    <img
                      src={data.companyPic}
                      alt=""
                      className="curator-cmpy"
                    />
                  )} */}
                                </div>
                              </div>
                            </div>
                            <p className="about-course">
                              {data.pathwayDescription}
                            </p>
                            <div className="course-footer">
                              <h3>
                                {data.courses && data.courses.length} Resources
                              </h3>
                              <Link to={`/pathway/${data._id}`}>
                                <button>View Pathway</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              {toggleState == 2 &&
                masterinone &&
                masterinone.map((data, id) => {
                  return (
                    <>
                      <div className="pathway-card1">
                        <div className="pathway-card-left">
                          <img src={data.coursePic} alt="" />
                          <button className="pathway-card-type">
                            {data.courseCategory}
                          </button>
                        </div>
                        <div className="pathway-card-right">
                          <div className="main-title">
                            <h4>{data.platformName}</h4>
                            <div>
                              <i class="bx bxs-star star-icon"></i>
                              {data.ratings && data.ratings}
                            </div>
                          </div>
                          <h3>{data.courseName}</h3>
                          <p className="about-course">
                            {data.courseDescription}
                          </p>
                          <div className="main-footer foot-bot">
                            <div className="time">
                              <i class="bx bx-timer"></i>
                              <p>{data.courseDuration}</p>
                            </div>

                            <div className="level">
                              <i class="bx bx-bar-chart-alt"></i>
                              <p>{data.difficulty}</p>
                            </div>
                          </div>
                          <a
                            href={data.CTALink}
                            target="_blank"
                            className="course-link"
                          >
                            <button className="learn-more foot-bot-bnt">
                              <i class="bx bx-chevron-right"></i>Learn More
                            </button>
                          </a>
                        </div>
                      </div>
                    </>
                  );
                })}

              {toggleState == 3 &&
                specalized &&
                specalized.map((data, id) => {
                  return (
                    <>
                      <div className="pathway-card1">
                        <div className="pathway-card-left">
                          <img src={data.coursePic} alt="" />
                          <button className="pathway-card-type">
                            {data.courseCategory}
                          </button>
                        </div>
                        <div className="pathway-card-right">
                          <div className="main-title">
                            <h4>{data.platformName}</h4>
                            <div>
                              <i class="bx bxs-star star-icon"></i>
                              {data.ratings && data.ratings}
                            </div>
                          </div>
                          <h3>{data.courseName}</h3>
                          <p className="about-course">
                            {data.courseDescription}
                          </p>
                          <div className="main-footer foot-bot">
                            <div className="time">
                              <i class="bx bx-timer"></i>
                              <p>{data.courseDuration}</p>
                            </div>

                            <div className="level">
                              <i class="bx bx-bar-chart-alt"></i>
                              <p>{data.difficulty}</p>
                            </div>
                          </div>
                          <a
                            href={data.CTALink}
                            target="_blank"
                            className="course-link"
                          >
                            <button className="learn-more foot-bot-bnt">
                              <i class="bx bx-chevron-right"></i>Learn More
                            </button>
                          </a>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        )}
      </div>
      <Footer2 />
    </>
  );
};

export default CoursesList;
