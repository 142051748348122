import React, { useEffect } from "react";
import "./thankyou.css";
import r from "../../images/rocket.png";
import { Link } from "react-router-dom";
import logo1 from "../../images/logo1.png";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const Thankyou = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="ty-page">
        {" "}
        <Toaster position="bottom-left" reverseOrder={false} />
        <div className="ty-container">
          <img src={r} alt="" className="ty-img" />
          <h3>Thank You</h3>
          <p>
            For signing up for Neo Learn. We will keep you updated via Email
            Awesome resources in your inbox soon{" "}
          </p>
          <Link to="/" onClick={() => navigate("/")}>
            <button className="back-btn">Back to Home</button>
          </Link>
          <div className="nav-logo mt-1">
            <img src={logo1} alt="" />
          </div>
          <div className="ty-icons">
            <div className="ty-social-media">
              <a href="https://www.instagram.com/neo_learn/" target="_blank">
                <i class="bx bxl-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/neolearners/"
                target="_blank"
              >
                <i class="bx bxl-linkedin"></i>
              </a>
              <a href="https://twitter.com/neo_learn" target="_blank">
                <i class="bx bxl-twitter"></i>
              </a>
              <a href="https://www.facebook.com/neolearns/" target="_blank">
                <i class="bx bxl-meta"></i>
              </a>
            </div>
            <p>Follow us on Social Media for updates </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
