import React from "react";
import "./how.css";

const How = () => {
  return (
    <div className="how-container">
      <h1>How Neolearn Works</h1>
      <div className="how-flex">
        <div className="how-img-container">
          <img
            src="https://res.cloudinary.com/dg9gicndn/image/upload/v1656313614/neolearn/Group_13164_1_hbjmmk.png"
            alt=""
            className="how-img"
          />
        </div>
        <div className="how-data">
          <h3>Step 1</h3>
          <h5>Choose what you want to Learn</h5>
          <p>We have covered most of the fields from tech to non tech</p>
        </div>
      </div>
      <div className="how-flex order-flip">
        <div className="how-data">
          <h3>Step 2</h3>
          <h5>Choose from the list of Pathways</h5>
          <p>
            We have a varirty of pathways curated by experts who have achieved a
            career goal.
          </p>
        </div>
        <div className="how-img-container">
          <img
            src="https://res.cloudinary.com/dg9gicndn/image/upload/v1656313615/neolearn/MacBook_Pro_14__-_10_1_1_q0qrsu.png"
            alt=""
            className="how-img"
          />
        </div>
      </div>
      <div className="how-flex ">
        <div className="how-img-container">
          <img
            src="https://res.cloudinary.com/dg9gicndn/image/upload/v1656314026/neolearn/MacBook_Pro_14__-_9_1_dkxraf.png"
            alt=""
            className="how-img"
          />
        </div>
        <div className="how-data">
          <h3>Step 3</h3>
          <h5>Follow a Pathway</h5>
          <p>
            You will find a list of resources in a organized fashion which will
            help you to achieve your career goal
          </p>
        </div>
      </div>
    </div>
  );
};

export default How;
