import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import n1 from "../../images/n1.png";
import logo1 from "../../images/logo1.png";

const Navbar = ({ handleBackClick, scroll }) => {
  const [showmenu, setshowmenu] = useState(false);

  return (
    <div ref={scroll}>
      <nav>
        <div className="nav-container">
          <div className="nav-logo">
            <img src={logo1} alt="" />
          </div>
          {/* <div className={showmenu ? "nav-links show" : "nav-links"}>
            <i
              className={
                showmenu ? "bx bx-x nav-close show-close" : "bx bx-x nav-close "
              }
              onClick={() => setshowmenu(!setshowmenu)}
            ></i>

            <ul>
              <li onClick={() => setshowmenu(!showmenu)}>Home</li>
              <li onClick={() => setshowmenu(!showmenu)}>Product</li>
              <li onClick={() => setshowmenu(!showmenu)}>Pricing</li>
              <li onClick={() => setshowmenu(!showmenu)}>Contact Us</li>
            </ul>
          </div> */}
          <div className="nav-right">
            {/* <div className="nav-login">Login</div> */}

            <button className="nav-join-us" onClick={() => handleBackClick()}>
              Get Access <i className="bx bx-right-arrow-alt"></i>
            </button>
          </div>
          {/* <div className="nav-menu-icon">
            <i
              className="bx bx-menu-alt-right menu-icon"
              onClick={() => setshowmenu(!showmenu)}
            ></i>
          </div> */}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
