import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import "./header.css";
import Scrolllist from "./Scrolllist";
import Writeform from "./Writeform";

const Header = ({ myRef }) => {
  return (
    <>
      <div className="header-container" ref={myRef}>
        <div className="header-main">
          <p className="header-main-p1">Start Learning what you love</p>
          <h2>Best Learning</h2>
          <h2>Opportunities</h2>
          <p className="header-main-text">
            Collection of best free and paid resources at one place based on
            <b> people’s experiences </b>who achieved the same career goal you
            wanted to achieve
          </p>
          {/* <div>
            <button className="header-start">Start Learning</button>
            <button className="header-signup">Sign Up</button>
          </div> */}
        </div>
      </div>
      <Writeform />

      <div className="mob-scroll-list">
        <Scrolllist />
      </div>
    </>
  );
};

export default Header;

{
  /* <div className="header-list">
          <button className="header-btn bns">
            <div>
              <i className="bx bxs-business logo-icon"></i>
            </div>
            Business
          </button>
          <button className="header-btn as ">
            <div>
              <i class="bx bx-cloud logo-icon"></i>
            </div>
            Autonom system
          </button>
          <button className="header-btn ds">
            <div>
              <i class="bx bx-data logo-icon"></i>
            </div>
            Data Science
          </button>
          <button className="header-btn dsn">
            <div>
              <i class="bx bxl-deviantart"></i>
            </div>
            Design
          </button>
        </div> */
}

{
  /* <div className="header-list">
          <button className="header-btn react">
            <div>
              <i class="bx bxl-react logo-icon"></i>
            </div>
            React
          </button>
          <button className="header-btn pm">
            <div>
              <i class="bx bx-laptop logo-icon"></i>
            </div>
            Product Management
          </button>
          <button className="header-btn ai">
            <div>
              <i class="bx bx-brain logo-icon"></i>
            </div>
            Artificial Intelligence{" "}
          </button>
          <button className="header-btn aws">
            <div>
              <i class="bx bxs-package logo-icon"></i>
            </div>
            AWS
          </button>
        </div> */
}
