import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import t1 from "../../images/t1.jpeg";
import t2 from "../../images/t2.jpeg";
import t3 from "../../images/t3.jpeg";
import t4 from "../../images/t4.jpeg";
import testdef from "../../images/testdef.jpg";

import "swiper/css";
import "swiper/css/pagination";
import "./Testcard.css";
import "swiper/css/navigation";

import SwiperCore, { Navigation, Pagination } from "swiper";
import TestCard1 from "./TestCard1";

SwiperCore.use([Navigation]);

export const TestimonialCards = () => {
  return (
    <div className="testcardswiper">
      <Swiper
        navigation={true}
        spaceBetween={10}
        className="mySwiper"
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1350: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        <SwiperSlide>
          <TestCard1
            testimg={t1}
            cmt=" This is a great
            website to find all the resources that you need under one roof in an
            organised fashion. The best part is you get to compare both paid and
            unpaid resources and choose whichever suits your needs. It saves you
            a lot of time and money and allows you to focus on upskilling
            yourself!"
            name="Anirudh Sai S B"
            desg="Product Management Intern at Peakmind"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestCard1
            testimg={t2}
            cmt=" NeoLearn assisted me in developing important skills and provided
            career guidance that was quite beneficial. It accelerated the
            learning process and lowered my efforts to find the finest materials
            available. The learning paths made it simple to focus and not be
            concerned about what needed to be done next. I whole heartedly
            endorse Neo Learn and its creators."
            name="Amol Jain"
            desg=" Deloitte USI (Business Technology Analyst)"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestCard1
            testimg={t3}
            cmt=" Neo learn helped me to find free UX design courses and it was very
            helpful with a great introduction course that was very easy to
            follow and really helped me to get an understanding about ux design
            and heuristic principles. The pathways helped to learn all concepts
            in a systematic manner in my ux design journey. I'm very happy to be
            a Neolearner and excited about its journey"
            name="Oviya S E"
            desg=" National institute of fashion technology"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestCard1
            testimg={t4}
            cmt="  Hi, This is Sethupathi, Product Engineer. I was really struck with
            studies and I did not have any idea from where to start learning
            development. Neolearn helped me a lot. They explained clearly from
            where to start and provided a clear learning track in the form of
            pathways. Now I have become a successful engineer. Thanks Team
            Neolearn"
            name="Sethupathi"
            desg="Product Engineer"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestCard1
            testimg={testdef}
            cmt="   Hi this is Aravind , I am a Backend developer, Neolearn helped me to
            figure out the exact way in which i was looking for to start my
            learning journey and as a result it opened multiple opportunities
            for me to upskill my self. Thanks Neolearn"
            name="Aravind"
            desg="Backend developer"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
