import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import Scrolllist from "./Scrolllist";

import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ReactLoading from "react-loading";
import base from "../../base";
import axios, { Axios } from "axios";

const Writeform = () => {
  const [email, setemail] = useState("");
  const [others, setothers] = useState("");
  const form = useRef();
  const alert = useAlert();
  const [tech, settech] = useState("Programming");
  let navigate = useNavigate();
  const [loading, setloading] = useState(false);

  let technologies = [
    "Programming",
    "Web development",
    "App development",
    "Design",
    "Product Management",
    "Data Science/AI/ML",
    "Blockchain",
    "Others",
  ];

  async function sendEmail(e) {
    e.preventDefault();

    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      base("Projects").create({ email, tech, others }, function (err, record) {
        if (err) {
          console.error(err);
          toast.error("something went wrong");
          return;
        }
      });
      toast.success("Thankyou for response has been saved!");
      navigate("/courselist");
      const res = await axios.post("https://neomailer1.herokuapp.com/send", {
        email: email,
      });

      // .then(function (response) {
      //   setloading(false);
      //   navigate("/thankyou");

      //   setTimeout(() => toast.success("Your response has been saved!"), 200);
      // })
      // .catch(function (error) {
      //   console.log(error);
      //   setloading(false);

      //   toast.error("something went wrong");
      // });
    } else {
      setloading(false);
      toast.error("Please Enter all fields");
    }
  }

  return (
    <>
      <div>
        <Toaster position="bottom-left" reverseOrder={false} />
      </div>
      <div
        className={tech == "Others" ? "write-form added-others" : "write-form"}
      >
        <form ref={form}>
          <div className="input-box mt-1">
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
            <i className="bx bx-envelope email-icon"></i>
          </div>
          <div className="select-box mt-1">
            <select
              name="tech"
              className="select-list"
              id="tech"
              onChange={(e) => settech(e.target.value)}
              value={tech}
            >
              {technologies.map((t) => {
                return <option value={t}>{t}</option>;
              })}
            </select>
          </div>
          {tech == "Others" && (
            <input
              className="input-box1 mt-1"
              name="other"
              type="text"
              onChange={(e) => setothers(e.target.value)}
              placeholder="Please enter the other technology"
            />
          )}
          <div>
            <button onClick={(e) => sendEmail(e)} className="submit mt-1">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Writeform;
