import React from "react";
import "./footer2.css";
import { Link } from "react-router-dom";

const Footer2 = () => {
  return (
    <div className="footer-2">
      <div>
        <Link to="/" className="link">
          <img
            src="https://res.cloudinary.com/neolearn/image/upload/v1663864574/Frame_274_xf5b7k.svg"
            alt=""
          />
        </Link>
      </div>
      <div className="footer-social-icons">
        <a href="https://www.linkedin.com/company/neolearners/" target="_blank">
          <i class="bx bxl-linkedin"></i>
        </a>
        <a href="https://www.instagram.com/neo_learn/" target="_blank">
          <i class="bx bxl-instagram"></i>
        </a>
        <a href="https://twitter.com/neo_learn" target="_blank">
          <i class="bx bxl-twitter"></i>
        </a>
        <a href="https://www.facebook.com/neolearns/" target="_blank">
          <i class="bx bxl-meta"></i>
        </a>
      </div>
    </div>
  );
};

export default Footer2;
