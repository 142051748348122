import React from "react";

import "./testmonial.css";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import t1 from "../../images/t1.jpeg";
import t2 from "../../images/t2.jpeg";
import t3 from "../../images/t3.jpeg";
import t4 from "../../images/t4.jpeg";
import { TestimonialCards } from "./TestimonialCards";

export const Testmonial = () => {
  return (
    <div className="testmonial-container">
      <div className="test-main">
        <h4>Testmonials</h4>
        <h1>Students Love our Content Curations</h1>
        <p>We are trying to solve the information overload problem for you</p>
      </div>
      <TestimonialCards />
      {/* <div className="test-cards">
        <div className="card">
          <div className="ratings">
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={4.5}
                precision={0.5}
                readOnly
              />
            </Stack>
          </div>
          <p className="user-cmt">
            Neo learn helped me to find free UX design courses and it was very
            helpful with a great introduction course that was very easy to
            follow and really helped me to get an understanding about ux design
            and heuristic principles. The pathways helped to learn all concepts
            in a systematic manner in my ux design journey. I'm very happy to be
            a Neolearner and excited about its journey
          </p>
          <div className="user">
            <img src={t3} alt="" />
            <div>
              <h3 className="user-name">Oviya S E</h3>
              <h4 className="user-role">
                National institute of fashion technology
              </h4>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="ratings">
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={4.5}
                precision={0.5}
                readOnly
              />
            </Stack>
          </div>
          <p className="user-cmt">
            Product Management Intern at Peakmind Testimonial: This is a great
            website to find all the resources that you need under one roof in an
            organised fashion. The best part is you get to compare both paid and
            unpaid resources and choose whichever suits your needs. It saves you
            a lot of time and money and allows you to focus on upskilling
            yourself!
          </p>
          <div className="user">
            <img src={t1} alt="" />
            <div>
              <h3 className="user-name">Anirudh Sai S B</h3>
              <h4 className="user-role">
                Product Management Intern at Peakmind
              </h4>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="ratings">
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={4.5}
                precision={0.5}
                readOnly
              />
            </Stack>
          </div>
          <p className="user-cmt">
            NeoLearn assisted me in developing important skills and provided
            career guidance that was quite beneficial. It accelerated the
            learning process and lowered my efforts to find the finest materials
            available. The learning paths made it simple to focus and not be
            concerned about what needed to be done next. I whole heartedly
            endorse Neo Learn and its creators.
          </p>
          <div className="user-wrapper">
            <div className="user">
              <img src={t2} alt="" />
              <div>
                <h3 className="user-name">Amol Jain</h3>
                <h4 className="user-role">
                  Deloitte USI (Business Technology Analyst)
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="ratings">
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={4.5}
                precision={0.5}
                readOnly
              />
            </Stack>
          </div>
          <p className="user-cmt">
            Hi, This is Sethupathi, Product Engineer. I was really struck with
            studies and I did not have any idea from where to start learning
            development. Neolearn helped me a lot. They explained clearly from
            where to start and provided a clear learning track in the form of
            pathways. Now I have become a successful engineer. Thanks Team
            Neolearn
          </p>
          <div className="user">
            <img src={t4} alt="" />
            <div>
              <h3 className="user-name">Sethupathi</h3>
              <h4 className="user-role">Product Engineer</h4>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="ratings">
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={4.5}
                precision={0.5}
                readOnly
              />
            </Stack>
          </div>
          <p className="user-cmt">
           c
          </p>
          <div className="user">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxLtdifYeywRo54PEZid8SrOliHnblV4GNEQ&usqp=CAU"
              alt=""
            />
            <div>
              <h3 className="user-name">Aravind</h3>
              <h4 className="user-role">Backend developer</h4>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
