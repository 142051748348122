import React from "react";
import "./Testcard.css";

const TestCard1 = ({ cmt, testimg, name, desg }) => {
  return (
    <div className="card1">
      <i className="bx bxs-quote-left quotest"></i>
      <p className="user-cmt1">{cmt}</p>
      <i className="bx bxs-quote-right quotesb"></i>
      <div className="user1">
        <img src={testimg} alt="" />
        <div>
          <h3 className="user-name1">{name}</h3>
          <h4 className="user-role1">{desg}</h4>
        </div>
      </div>
    </div>
  );
};

export default TestCard1;
