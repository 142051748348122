import React from "react";
import Writeform from "../Header/Writeform";
import "./footer.css";
import n2 from "../../images/n2.png";
import logo2 from "../../images/logo2.png";
const Footer = ({ handleBackClick }) => {
  return (
    <>
      <div className="footer-container">
        <div className="footer-main">
          <div className="products">
            <h4>Save your time and money</h4>
            <h1>Featured Learning resources</h1>
            <p>Get the best courses handpicked by our experts in your inbox</p>
          </div>
          {/* <div>
            <button className="footer-e-btn" onClick={() => handleBackClick()}>
              Get early access
            </button>
          </div> */}
          {/* <div className="newsletter">
            <input type="email" placeholder="Your Email" />
            <button className="subscribe">Subscribe</button>
          </div> */}
        </div>
      </div>
      <Writeform />
      <div className="footer">
        <div className="footer-left">
          <img src={logo2} alt="" className="footer-logo" />
        </div>
        <div className="footer-right">
          <div className="social-media">
            <a href="https://www.instagram.com/neo_learn/" target="_blank">
              <i class="bx bxl-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/neolearners/"
              target="_blank"
            >
              <i class="bx bxl-linkedin"></i>
            </a>
            <a href="https://twitter.com/neo_learn" target="_blank">
              <i class="bx bxl-twitter"></i>
            </a>
            <a href="https://www.facebook.com/neolearns/" target="_blank">
              <i class="bx bxl-meta"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
{
  /* <div className="f-card">
          <div className="f-text">
            <div className="f-heading">Get In Touch</div>
            <p>the quick fox jumps over the lazy dog</p>
            <div className="social-media">
              <i class="bx bxl-instagram"></i>
              <i class="bx bxl-linkedin"></i>
              <i class="bx bxl-twitter"></i>
              <i class="bx bxl-meta"></i>
            </div>
          </div>
        </div>
        <div className="f-card">
          <div className="f-text">
            <div className="f-heading">Company info</div>
            <p>About Us</p>
            <p>About Us</p>
            <p>We are hiring</p>
            <p>Blog</p>
          </div>
        </div>
        <div className="f-card">
          <div className="f-text">
            <div className="f-heading">Features</div>
            <p>Business Marketing</p>
            <p>User Analytic</p>
            <p>Live Chat</p>
            <p>Unlimited Support</p>
          </div>
        </div>
        <div className="f-card">
          <div className="f-text">
            <div className="f-heading">Resources</div>
            <p>IOS & Android</p>
            <p>Customers</p>
            <p>We are hiring</p>
            <p>API</p>
          </div>
        </div> */
}
