import React, { useRef, useEffect } from "react";
import Company from "../components/company/Company";
import Loading from "../components/courses/Loading";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HeaderCards from "../components/Header/HeaderCards";
import Navbar from "../components/Navbar/Navbar";
import { Testmonial } from "../components/testmonial/Testmonial";
import Writeus from "../components/Writeus/Writeus";
import ReactGA from "react-ga";
import How from "../components/How/How";

const Home = () => {
  let myRef = useRef();
  let scroll = useRef();
  function handleBackClick() {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollClick() {
    scroll.current.scrollIntoView({ behavior: "smooth" });
  }

  function scrollUp() {
    const scrollY = window.pageYOffset;
    const scrollUp = document.getElementById("scroll-up");

    if (scrollUp.classList) {
      if (scrollY >= 350) {
        scrollUp.classList.add("show-scroll");
      } else {
        scrollUp.classList.remove("show-scroll");
      }
    }
  }
  window.addEventListener("scroll", scrollUp);

  // useEffect(() => {
  //   function setGA() {
  //     ReactGA.initialize("G-WRHW18YN5J");
  //     ReactGA.pageview("Init page view");
  //   }
  //   setGA();
  // }, []);

  //   function GetLocation() {
  //     var geocoder = new google.maps.Geocoder();
  //     var address = document.getElementById("txtAddress").value;
  //     geocoder.geocode({ 'address': address }, function (results, status) {
  //         if (status == google.maps.GeocoderStatus.OK) {
  //             var latitude = results[0].geometry.location.lat();
  //             var longitude = results[0].geometry.location.lng();
  //             alert("Latitude: " + latitude + "\nLongitude: " + longitude);
  //         } else {
  //             alert("Request failed.")
  //         }
  //     });
  // }

  return (
    <div className="bghome">
      <Navbar handleBackClick={handleBackClick} scroll={scroll} />
      <Header myRef={myRef} />
      <Company />
      <HeaderCards />
      <How />
      {/* <Writeus handleBackClick={handleBackClick} /> */}
      <Loading handleBackClick={handleBackClick} />
      <Testmonial />
      <Footer handleBackClick={handleBackClick} />
      <div
        className="scrollup show-scroll"
        id="scroll-up"
        onClick={() => scrollClick()}
      >
        <i className="bx bx-up-arrow-alt scrollup__icon"></i>
      </div>
    </div>
  );
};

export default Home;
