export const steps = [
  {
    id: "second",
    attachTo: {
      element: ".third-step",
      on: "top",
    },
    title: "Welcome to NeoLearn!",
    text: [
      "Collection of best free and paid resources at one place based on people’s experiences who achieved the same career goal you wanted to achieve.",
    ],
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Exit",
        type: "cancel",
      },
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      show: () => {
        console.log("show stepp");
      },
      hide: () => {
        console.log("complete step");
      },
    },
    highlightClass: "highlight",

    showCancelLink: false,
    scrollTo: true,
    modalOverlayOpeningPadding: 4,
    useModalOverlay: false,
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: {
      element: ".second-step",
      on: "top",
    },
    attachTo: { element: ".tab-heading", on: "bottom" },
    scrollTo: true,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Exit",
        type: "cancel",
      },
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    showCancelLink: true,
    title: "NeoLearn",
    text: [`Learn about the type of resource`],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },

  {
    id: "third",
    attachTo: {
      element: ".second-step",
      on: "top",
    },
    attachTo: { element: ".course-details", on: "bottom" },
    scrollTo: true,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Exit",
        type: "cancel",
      },
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    showCancelLink: true,
    title: "NeoLearn",
    text: [
      `Mentor has learnt from these resources to launch his career successfully`,
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
];
