import React from "react";
import "./login.css";
import svg1 from "./assets/8k13.svg";
const Login = () => {
  const google = () => {
    window.open("http://localhost:5000/auth/google", "_self");
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <img src={svg1} className="img-container" />
      </div>

      <div className="login-right">
        <h4>Welcome back</h4>
        <h2>Login to your account</h2>
        <div className="login-lable mt">
          <div>
            <label htmlFor="email">Email</label>
          </div>
          <div>
            <input type="email" id="email" />
          </div>
        </div>

        <div className="login-lable mt">
          <div>
            {" "}
            <label htmlFor="password">Password</label>
          </div>

          <div>
            <input type="password" id="password" />
          </div>
        </div>
        <div>
          <p className="login-forgot">Forgot Password?</p>
        </div>
        <div>
          <button className="login-btn mt">Login now</button>
        </div>
        <div>
          <button className="google-btn mt" onClick={google}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmvXAwGcMTepcDIwjTsd8viPQu31r-vlgzRQ&usqp=CAU"
              alt=""
              className="g-icon"
            />
            Sing-in with Google
          </button>
          <div className="login-footer">
            Dont have an account <span>Join today!</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
