import React from "react";
import "./courses.css";

const Courses = () => {
  return (
    <div className="courses-container">
      <div className="courses-main">
        <div className="course-main-heading">
          <h3>Best Resources handpicked for you</h3>
          <h2>Best Courses At Best price</h2>
          <p>These courses</p>
        </div>
        <div className="coming-soon">
          <img
            src="https://media.istockphoto.com/vectors/coming-soon-isolated-vector-icon-paper-style-promotion-sign-start-a-vector-id1273109788?k=20&m=1273109788&s=612x612&w=0&h=JStiZA3z_OG2FmCz5ZlV4axsxGWIy6-LrdOVTuZsyKg="
            alt=""
          />
        </div>

        <div className="courses">
          {/* <h1 className="card-coming-soon">Coming Soon!</h1> */}
          <div className="course-card">
            <div className="course-img-container">
              <img
                className="course-img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzf4dKcnKHm-M72VvRDEADX6L0JduryUhxhQ&usqp=CAU"
                alt=""
              />
              <span>Sale</span>
            </div>
            <div className="card-main">
              <div className="main-title">
                <p>Udemy</p>
                <div>
                  <i class="bx bxs-star star-icon"></i>4.9
                </div>
              </div>
              <div className="main-course-title">Graphic Design</div>
              <div className="main-desc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non,
                illo.
              </div>
              <div className="main-stud-cnt">
                <i class="bx bx-download"></i>10000+ Students
              </div>
              <div className="price">
                <p className="p1">
                  <span>Rs</span> 500
                </p>
                <p className="p2">
                  <span>Rs</span> 500
                </p>
              </div>
              <div className="main-footer">
                <div className="time">
                  <i class="bx bx-timer"></i>
                  <span>22hrs 30min</span>
                </div>
                <div className="level">
                  <i class="bx bx-bar-chart-alt"></i>
                  <span>Beginner</span>
                </div>
              </div>
              <button className="learn-more">
                <i class="bx bx-chevron-right"></i>Learn More
              </button>
            </div>
          </div>
          <div className="course-card">
            <div className="course-img-container">
              <img
                className="course-img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzf4dKcnKHm-M72VvRDEADX6L0JduryUhxhQ&usqp=CAU"
                alt=""
              />
              <span>Sale</span>
            </div>
            <div className="card-main">
              <div className="main-title">
                <p>Udemy</p>
                <div>
                  <i class="bx bxs-star star-icon"></i>4.9
                </div>
              </div>
              <div className="main-course-title">Graphic Design</div>
              <div className="main-desc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non,
                illo.
              </div>
              <div className="main-stud-cnt">
                <i class="bx bx-download"></i>10000+ Students
              </div>
              <div className="price">
                <p className="p1">
                  <span>Rs</span> 500
                </p>
                <p className="p2">
                  <span>Rs</span> 500
                </p>
              </div>
              <div className="main-footer">
                <div className="time">
                  <i class="bx bx-timer"></i>
                  <span>22hrs 30min</span>
                </div>
                <div className="level">
                  <i class="bx bx-bar-chart-alt"></i>
                  <span>Beginner</span>
                </div>
              </div>
              <button className="learn-more">
                <i class="bx bx-chevron-right"></i>Learn More
              </button>
            </div>
          </div>
          <div className="course-card">
            <div className="course-img-container">
              <img
                className="course-img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzf4dKcnKHm-M72VvRDEADX6L0JduryUhxhQ&usqp=CAU"
                alt=""
              />
              <span>Sale</span>
            </div>
            <div className="card-main">
              <div className="main-title">
                <p>Udemy</p>
                <div>
                  <i class="bx bxs-star star-icon"></i>4.9
                </div>
              </div>
              <div className="main-course-title">Graphic Design</div>
              <div className="main-desc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non,
                illo.
              </div>
              <div className="main-stud-cnt">
                <i class="bx bx-download"></i>10000+ Students
              </div>
              <div className="price">
                <p className="p1">
                  <span>Rs</span> 500
                </p>
                <p className="p2">
                  <span>Rs</span> 500
                </p>
              </div>
              <div className="main-footer">
                <div className="time">
                  <i class="bx bx-timer"></i>
                  <span>22hrs 30min</span>
                </div>
                <div className="level">
                  <i class="bx bx-bar-chart-alt"></i>
                  <span>Beginner</span>
                </div>
              </div>
              <button className="learn-more">
                <i class="bx bx-chevron-right"></i>Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="see-more">see more</p>
    </div>
  );
};

export default Courses;
