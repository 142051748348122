import React from "react";
import "./company.css";
import CompanyScroll from "./CompanyScroll";

const Company = () => {
  return (
    <div className="company-container">
      <div className="company-heading">Curated by Industry Experts</div>
      <p>
        Awesome Mentors who work at this top companies have curated this list of
        courses to help you
      </p>
      <CompanyScroll />
    </div>
  );
};

export default Company;
