import { height } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer2 from "../Footer2/Footer2";
import Navbar2 from "../Navbar2/Navbar2";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import "./pathway.css";
import toast, { Toaster } from "react-hot-toast";
import base from "../../base";
import { steps } from "./steps";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

function Button() {
  const tour = useContext(ShepherdTourContext);

  return (
    <button className="tour-btn" onClick={tour.start}>
      Start Tour
    </button>
  );
}

const Pathway1 = () => {
  const [data, setdata] = useState();
  const [courselen, setcourselen] = useState();
  const [coulen, setcoulen] = useState();
  const tour = useContext(ShepherdTourContext);
  const [email, setemail] = useState("");
  const [emailsent, setemailsent] = useState(false);

  // useEffect(() => {
  //   tour.start();
  // }, []);

  async function sendmail(e) {
    e.preventDefault();

    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      base("users").create({ email }, function (err, record) {
        if (err) {
          console.error(err);
          toast.error("something went wrong");
          return;
        }
      });

      setemail("");
      setemailsent(true);

      toast.success("Your response has been saved!");
    } else {
      toast.error("Please Enter a valid email id");
    }
  }

  let { id } = useParams();
  useEffect(() => {
    async function getapi() {
      try {
        const resp = await axios.get(
          `https://neolearn-backend.onrender.com/api/pathway/${id}`
        );

        setdata(resp.data.message);
        setcourselen(resp.data.message.courses.length);

        setcourselen(courselen * 20);
        setcoulen(resp.data.message.courses.length);
      } catch (error) {
        return "something went wrong";
      }
    }
    getapi();
  }, []);

  // console.log(
  //   courselen * (10 + courselen - 1),
  //   "hello",
  //   courselen / 100 + 0.105
  // );

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <Navbar2 />

      <div className="pathway-container">
        <div className="pathway-header">
          <h1>
            Explore Pathways
            <ShepherdTour steps={steps} tourOptions={tourOptions}>
              <Button />
            </ShepherdTour>
          </h1>{" "}
          <div className="pathway-detail">
            <Link to="/courselist" className="link">
              Pathway
            </Link>
            <i class="bx bx-chevron-right p-bold"></i>{" "}
            <b>
              {data && data.pathwayName} Pathway by {data && data.curatedBy}{" "}
            </b>
          </div>
          <div className="curator-details ">
            <b>
              {data && data.pathwayName} Pathway by {data && data.curatedBy}{" "}
            </b>
            <div className="curator-bg">
              {data && (
                <img src={data.profilePic} alt="" className="profile-pic" />
              )}
              <h6>
                {data && data.curatedBy} ,{data && data.personDesignation}
              </h6>
            </div>
            <div className="connect-div">
              Connect with Mentor:{" "}
              {data && data.linkedinUrl ? (
                <a href={data.linkedinUrl} target="_blank">
                  <img
                    className="connect-img"
                    src="https://cdn3.iconfinder.com/data/icons/inficons/512/linkedin.png"
                    alt=""
                  />
                </a>
              ) : (
                <img
                  className="connect-img"
                  src="https://cdn3.iconfinder.com/data/icons/inficons/512/linkedin.png"
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="pathway">
            <div className="pathway-left">
              <div className="pathway-left-container">
                <div
                  className="line1"
                  // style={{
                  //   height: `100%`,
                  //   marginTop: `10%`,
                  //   marginBot: `50%`,
                  //   // marginTop: `20%`,
                  // }}
                >
                  {" "}
                </div>
                <div className="pathway-card-no">
                  <button className="card-btn1 start">Start</button>
                </div>
                {data &&
                  data.courses.map((course, id) => {
                    return (
                      <>
                        {(course.courseCategory == "Course" ||
                          course.courseCategory == "Free Course" ||
                          course.courseCategory == "Paid Course") && (
                          <div className="pathway-line">
                            <div className="pathway-card-no">
                              <button className="card-btn">{id + 1}</button>
                            </div>
                            <div className="pathway-card">
                              <div className="pathway-card-left">
                                <img src={course.coursePic} alt="" />
                                <button className="pathway-card-type">
                                  {course.courseCategory}
                                </button>
                              </div>
                              <div className="pathway-card-right">
                                <div className="main-title">
                                  <h4>{course.platformName}</h4>
                                  <div>
                                    <i class="bx bxs-star star-icon"></i>
                                    {course.ratings && course.ratings}
                                  </div>
                                </div>
                                <h3>{course.courseName}</h3>
                                <p>
                                  {course.courseDescription}
                                  {/* This is a medium blog written by the ML Engineer
                    at facebook */}
                                </p>
                                <div className="main-footer">
                                  <div className="time">
                                    <i class="bx bx-timer"></i>
                                    <p>{course.courseDuration}</p>
                                  </div>

                                  <div className="level">
                                    <i class="bx bx-bar-chart-alt"></i>
                                    <p>{course.difficulty}</p>
                                  </div>
                                </div>
                                <a
                                  href={course.CTALink}
                                  target="_blank"
                                  className="course-link"
                                >
                                  <button className="learn-more">
                                    <i class="bx bx-chevron-right"></i>Learn
                                    More
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        )}

                        {(course.courseCategory == "Blog" ||
                          course.courseCategory == "Docs" ||
                          course.courseCategory == "Podcast" ||
                          course.courseCategory == "Interview prep" ||
                          course.courseCategory == "Book" ||
                          course.courseCategory == "Practice") && (
                          <div className="pathway-line">
                            <div className="pathway-card-no">
                              <button className="card-btn">{id + 1}</button>
                            </div>
                            <div className="pathway-card">
                              <div className="pathway-card-left">
                                <img src={course.coursePic} alt="" />
                                <button className="pathway-card-type">
                                  {course.courseCategory &&
                                    course.courseCategory}
                                </button>
                              </div>
                              <div className="pathway-card-right">
                                <div className="main-title">
                                  <h4>
                                    {course.platformName && course.platformName}
                                  </h4>
                                  <div>
                                    <i class="bx bxs-star star-icon"></i>
                                    {course.ratings && course.ratings}
                                  </div>
                                </div>
                                <h3>
                                  {course.courseName && course.courseName}
                                </h3>
                                <p>
                                  {course.courseDescription &&
                                    course.courseDescription}
                                  {/* This is a medium blog written by the ML Engineer
                    at facebook */}
                                </p>
                                <div className="main-footer">
                                  <div className="time">
                                    <i class="bx bx-timer"></i>
                                    <p>
                                      {course.courseDuration &&
                                        course.courseDuration}
                                    </p>
                                  </div>

                                  <div className="level">
                                    <i class="bx bx-bar-chart-alt"></i>
                                    <p>
                                      {course.difficulty && course.difficulty}
                                    </p>
                                  </div>
                                </div>
                                <a
                                  href={course.CTALink}
                                  target="_blank"
                                  className="course-link"
                                >
                                  <button className="learn-more">
                                    <i class="bx bx-chevron-right"></i>Learn
                                    More
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        {(course.courseCategory == "Youtube Channel" ||
                          course.courseCategory == "Youtube Playlist" ||
                          course.courseCategory == "Youtube Video" ||
                          course.courseCategory == "Youtube") && (
                          <div className="pathway-line">
                            <div className="pathway-card-no">
                              <button className="card-btn">{id + 1}</button>
                            </div>
                            <div className="pathway-card">
                              <div className="pathway-card-left">
                                <img src={course.coursePic} alt="" />
                                <button className="pathway-card-type">
                                  {course.courseCategory &&
                                    course.courseCategory}
                                </button>
                              </div>
                              <div className="pathway-card-right">
                                <div className="main-title">
                                  <h4>
                                    {course.platformName && course.platformName}
                                  </h4>
                                  <div>
                                    <i class="bx bxs-star star-icon"></i>
                                    {course.ratings && course.ratings}
                                  </div>
                                </div>
                                <h3>
                                  {course.courseName && course.courseName}
                                </h3>
                                <p>
                                  {course.courseDescription &&
                                    course.courseDescription}
                                </p>
                                <div className="main-footer">
                                  <div className="time">
                                    <i class="bx bx-timer"></i>
                                    <p>
                                      {" "}
                                      {course.courseDuration &&
                                        course.courseDuration}
                                    </p>
                                  </div>

                                  <div className="level">
                                    <i class="bx bx-bar-chart-alt"></i>
                                    <p>
                                      {course.difficulty && course.difficulty}
                                    </p>
                                  </div>
                                </div>
                                <a
                                  href={course.CTALink}
                                  target="_blank"
                                  className="course-link"
                                >
                                  <button className="learn-more">
                                    <i class="bx bx-chevron-right"></i>Learn
                                    More
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                <div className="pathway-card-no">
                  <button className="card-btn1 start">End</button>
                </div>
              </div>
            </div>
            <div className="pathway-rigth">
              <div className="p-right-container">
                <div className="signup-container">
                  <h3>
                    Sign Up for Neo learn{" "}
                    <img
                      className="signup-img"
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870283/neolearn/Emoji_lrq9un.png"
                      alt=""
                    />
                  </h3>
                  {emailsent ? (
                    <div className="signed-success">
                      <b> Signed in Successfully </b>

                      <img
                        className="signed-gif"
                        src="https://res.cloudinary.com/dg9gicndn/image/upload/v1665907176/output-onlinegiftools_yaofxl.gif"
                        alt=""
                      />
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <p>Get awesome value right into your inbox</p>
                      <div className="signup-input">
                        <input
                          type="email"
                          placeholder="Email"
                          onChange={(e) => setemail(e.target.value)}
                          value={email}
                        />
                        <button onClick={sendmail} className="send-eamil-btn">
                          send
                        </button>
                      </div>
                    </div>
                  )}{" "}
                  <p className="signup-list">
                    <img
                      className="tick"
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870361/neolearn/Vector_1_xc9j9s.png"
                      alt=""
                    />
                    No Spamming , Only valuable resources
                  </p>
                  <p className="signup-list">
                    <img
                      className="tick"
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870361/neolearn/Vector_1_xc9j9s.png"
                      alt=""
                    />
                    Free high quality courses by Standford
                  </p>
                  <p className="signup-list">
                    <img
                      className="tick"
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870361/neolearn/Vector_1_xc9j9s.png"
                      alt=""
                    />
                    Job oppurtunities from the best startups
                  </p>
                </div>
                <div className="explore-container">
                  <h3>
                    Explore other Pathways{" "}
                    <img
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870436/neolearn/Emoji_1_yrivt8.png"
                      alt=""
                    />
                  </h3>

                  <p className="explore-list">
                    <img
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870767/neolearn/Emoji_2_bccldc.png"
                      alt=""
                    />
                    Web Development
                  </p>
                  <p className="explore-list">
                    <img
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870767/neolearn/Emoji_2_bccldc.png"
                      alt=""
                    />
                    Android App development
                  </p>
                  <p className="explore-list">
                    <img
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870767/neolearn/Emoji_2_bccldc.png"
                      alt=""
                    />
                    Design
                  </p>
                  <p className="explore-list">
                    <img
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870767/neolearn/Emoji_2_bccldc.png"
                      alt=""
                    />
                    Marketing
                  </p>
                  <p className="explore-list">
                    <img
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870767/neolearn/Emoji_2_bccldc.png"
                      alt=""
                    />
                    Product Management
                  </p>
                  <p className="explore-list">
                    <img
                      src="https://res.cloudinary.com/dg9gicndn/image/upload/v1650870767/neolearn/Emoji_2_bccldc.png"
                      alt=""
                    />
                    +much more
                  </p>
                  <Link to="/courselist" className="link">
                    <button>Explore now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default Pathway1;
